.forecast {
    &__body {
        display: flex;
        position: relative;
    }
    &__controls {
        padding-top: 85px;
        min-height: 100vh;
        background-color: #171717;
        width: 25%;
        .heading {
            background: orange;
            padding: 5px 30px;
            font-size: 18px;
            color: #fff;
            font-weight: bold;
        }
        form {
            padding: 20px;
        }
    }
    &__results {
        width: 75%;
        padding: 90px 20px;
        .heading {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .line__container {
        width: 100%;
        height: 500px;
    }
    .res__filters {
        margin: 20px 0;
        width: 100%;
    }
    .res__filter-info {
        display: flex;
        justify-content: space-between;
    }
    .info-item {
        span {
            font-weight: bold;
        }
    }
}
