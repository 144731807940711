.landing {
    // font-family: 'Lato', sans-serif;
    // font-family: 'Lora', serif;
    &__hero {
        background: url('../../assets/images/hero-bg.png'), rgba(0, 0, 0, 0.6);
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 90vh;
        display: flex;
        align-items: center;
        &-content {
            max-width: 1300px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 60px 20px;

            @include breakpoint(xs) {
                display: block;
            }
        }

        &-p {
            width: 100%;
            color: #fff;

            padding: 30px 50px;

            @include breakpoint(xs) {
                width: 100%;
                padding: 20px;
            }
            .text {
                font-size: 60px;
                @include breakpoint(xs) {
                    font-size: 40px;
                }
            }
        }
    }
    &__mid {
        padding: 0 50px;
        max-width: 1300px;
        margin: 60px auto 40px;
        &-title {
            font-size: 40px;
            text-align: center;
            font-weight: bold;
            color: #fd7e14;
            font-family: 'Lora', serif;
        }
    }
    &__mid-products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 50px auto;
        gap: 70px;
        padding: 0 50px;
        @include breakpoint(ipad) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(xs) {
            grid-template-columns: repeat(1, 1fr);
            padding: 0 10px;
        }
        &--item {
            display: flex;
            flex-direction: column;
            text-align: center;
            text-decoration: none;

            a {
                text-decoration: none;
                text-align: center;
                margin: 0 auto;
                color: unset;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    &__icon {
        margin: 0 auto;
    }
    &__hr {
        border: 1px solid #fd7e14;
        max-width: 1300px;
        margin: 60px auto;
    }
    &__logos {
        margin-right: 20px;
    }
    &__container {
        max-width: 1300px;
        margin: 60px auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include breakpoint(xs) {
            display: block;
        }
    }
    &__contact {
        width: 30%;
        @include breakpoint(xs) {
            width: 100%;
            padding: 20px;
            text-align: center;
        }
    }
    &__footer {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        @include breakpoint(xs) {
            display: block;
            text-align: center;
            width: 100%;
            margin-right: 0;
        }
        &-logo {
            img {
                @include breakpoint(xs) {
                    margin: 0 auto !important;
                    width: 90% !important;
                }
            }
        }
        &-content {
            margin-left: 20px;
            @include breakpoint(xs) {
                margin: 0 auto !important;
            }
        }
        &-socials {
            display: flex;
            margin-top: 20px;
            @include breakpoint(xs) {
                justify-content: center;
            }
        }
    }
}

.btop {
    border-top: 2px solid #fd7e14;
    padding-top: 20px;
}
