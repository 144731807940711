.map {
    .mapContainer {
        height: 100vh;
        width: 100vw;
        position: relative;
        z-index: 80;
    }
}

.sr-only {
    display: none;
}

.map {
    .leaflet-control {
        margin-top: 100px !important;
    }
}

.legend__container {
    display: flex;
    align-items: center;

    .min,
    .max {
        font-size: 12px;
        padding: 10px;
    }
}
