.profile {
    position: relative;
    &__body {
        margin-top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        form {
            width: 50%;
            margin-right: 20px;
        }
        .premium {
            width: 50%;
            text-align: center;
            .spiel {
                font-weight: bold;
            }
        }
    }
    &__cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.sec--title {
    font-weight: bold;
    font-size: 30px;
}
